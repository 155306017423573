import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from "react-router-dom";
import Axios from 'axios';
import '../style/Traductor.css'
import downArrow from "../props/downArrow.png"
import upArrow from "../props/upArrow.png";
import { publish, subscribe, unsubscribe} from '../events'
import Tooltip from './Tooltip'

const Attribute = (keyAttribute) => {
    const search = useLocation().search;
    // URL PARAMS
    const pimInstance = new URLSearchParams(search).get('quableInstance')
    const quableDocID = new URLSearchParams(search).get('documentCode')
    const quableDocType = new URLSearchParams(search).get('documentType')
    const token = new URLSearchParams(search).get('token')
    const quableUserID = new URLSearchParams(search).get('quableUserID')
    let backURL = "/api/save"
    let errorURL = "/api/error"
    if (process.env.NODE_ENV === "development")
    {
        backURL="http://localhost:4000/api/save"
        errorURL="http://localhost:4000/api/error"
    }
    //States
    const [isExceedingMaxChars, setIsExceedingMaxChars] = useState(false);
    const [pimData,setPimData]=useState(keyAttribute.value?keyAttribute.value:"_")
    const [actualTranslation,setActualTranslation]=useState(keyAttribute.actualTranslation?keyAttribute.actualTranslation:"_")
    //bon j'ai inversé les deux, pas grave
    const [serviceTrad, setServiceTrad]=useState(keyAttribute.trad)
    const [idTranslation, setIdTranslation]=useState(keyAttribute.translationId)
    const [chosenTrad,setChosenTrad]=useState("")
    const [targetLocale,setTargetLocale]=useState(keyAttribute.locale)
    const [checked,setChecked]=useState(true)
    const checkedRef = useRef(checked)
    const serviceTradRef = useRef(serviceTrad)
    const idTranslationRef = useRef(idTranslation)
    const targetLocaleRef = useRef(targetLocale)
    const [isTextAreaVisible, setTextAreaVisible] = useState(false);
    const [error, setError] = useState({})
    //Event Handling
    const chargeIntoTraductor = () => {
        //console.log("Throwing event 'chargeIntoTraductor'")
        publish('chargeIntoTraductor',{instruction:!checkedRef.current,attributeCode:keyAttribute.attributeCode});
    }
    const fleche=" → "
    const toggleTextArea = () => {
        setTextAreaVisible(!isTextAreaVisible);
    };

    //Logical Functions
    async function handleValidation()
    {
        //console.log("checked ref & servtrad ref :",checkedRef.current,serviceTradRef.current)
        const { onSaveStart, onSaveEnd } = keyAttribute;

        if (checkedRef.current && serviceTradRef.current!="" && serviceTradRef.current!="x"){
            onSaveStart(keyAttribute.attributeCode);
            //envoyer la modification à Quable
            const contenu={}
            const attributes= {}
            let traduct;

            contenu[targetLocaleRef.current]=serviceTradRef.current
            attributes[keyAttribute.attributeCode]=contenu
            try {
                traduct = await Axios(
                    {
                        method: "PUT",
                        url: `${pimInstance}/documents/${quableDocID}`,//pimInstance+"/documents/"+quableDocID,
                        headers: {
                            'content-type': 'application/ld+json',
                            Accept: "application/hal+json,",
                            Authorization: "Bearer " + token,
                        },
                        data: {
                            attributes: attributes
                        }
                    })
                if (traduct.status !== 200) {
                    throw new Error("Error sending the data to Quable")
                }
            } catch (e) {
                setError({
                    'message': "A major Error has happenned. Contact the administrator",
                    'gravity': 'major'
                })
                try {
                    const responseError = await Axios({
                        method: "POST",
                        url: errorURL,
                        data: {
                            message: "Error sending the data to Quable",
                            gravity: "major"
                        }
                    })
                } catch (e){
                }
            } finally {
                onSaveEnd(keyAttribute.attributeCode);
                //publish("endOfPimUpdate")
            }
            if (traduct.status===200)
            {
                try {
                    const response = await Axios({
                        method: "POST",
                        url: backURL,
                        data: {
                            idTranslation: idTranslationRef.current,
                            translation: serviceTradRef.current,
                            userName:keyAttribute.userName
                        }
                    })
                }
                catch
                {
                    setError(
                        {
                            'message' : "A minor Error has happenned. Contact the administrator",
                            'gravity' : 'minor'
                        })
                    console.log("Error has happened while saving the data")
                }
            }
        }
        else
        {
            //TODO shouldn't be able to clic on the button if the attribute is not checked
            //console.log("Attribute "+keyAttribute.attribute+" not checked so is not sending to PIM")
        }
    }


    //Effects
    useEffect(()=>{
        chargeIntoTraductor()
        setChecked(false)
    },[])

    useEffect(()=>{
        setPimData(keyAttribute.value?keyAttribute.value:"_")
    },onclick)

    useEffect(()=>{
        setActualTranslation(keyAttribute.actualTranslation?keyAttribute.actualTranslation:"_")
    }, onclick)

    useEffect(() => {
        setIdTranslation(keyAttribute.translationId)
    }, [keyAttribute.translationId])

    useEffect(()=>{
        setServiceTrad(keyAttribute.trad)
    },[keyAttribute.trad])

    useEffect(()=>{
        setTargetLocale(keyAttribute.locale)
    },[keyAttribute.locale])

    useEffect(()=>{
        setChosenTrad(keyAttribute.trad)
    },[serviceTrad])



    useEffect(() => {
        const resetStates =  () => {
            setChecked(false);
            checkedRef.current = false
            setServiceTrad('x');
            serviceTradRef.current = 'x';
        };
        subscribe("resetAttributeStates", resetStates);

        return () => {
            unsubscribe("resetAttributeStates", resetStates);
        }
    }, []);

    useEffect(() => {
        //unsubscribe("sendToPim", () => console.log("killed event sendToPIM"))
        subscribe("sendToPim", () => {
            if (!checked)
            {
                console.log("Caught event sendToPIM ",checked.toString())
            }
            handleValidation()
        });

        subscribe("checkAllEvent",event=>{
            if(checkedRef.current != event.detail.instruction)
            {
                setChecked(event.detail.instruction)
                chargeIntoTraductor()
            }
        })

        return () => {
            unsubscribe("sendToPim", () => console.log("killed event sendToPIM"));
            unsubscribe("checkAllEvent", () => console.log("killed event checkAllEvent"))
        }
    },[])

    useEffect(()=>{
        serviceTradRef.current=serviceTrad
        idTranslationRef.current=idTranslation
        checkedRef.current=checked
        targetLocaleRef.current=targetLocale
    })

    return(<>
        <div key={keyAttribute.attribute} className='attributeDisplay'>
            <label style={{marginRight: "1%"}}>{keyAttribute.attribute}</label>
            <div className='attributeDiv'>
                <input
                    type='checkbox'
                    checked={checked}
                    onChange={() => {
                        setChecked(!checked)
                        chargeIntoTraductor()
                    }}
                />
                <textarea
                    type="text"
                    value={pimData}
                    disabled={true}
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        maxHeight: '10%',
                        resize: 'none',
                        minHeight: '60px'
                    }}
                />
                {fleche}
                <div
                    style={{
                        position: "relative",
                        width: '50%',
                        boxSizing: 'border-box',
                        maxHeight: '10%',
                        resize: 'none',
                        minHeight: '60px'
                    }}
                >
                    <textarea
                        type="text"
                        value={serviceTrad}
                        //maxLength={keyAttribute.maxCharactersAllowed}
                        onChange={event => {
                            const newTraduction = event.target.value;
                            const exceedsLimit = newTraduction.length > keyAttribute.maxCharactersAllowed;
                            setIsExceedingMaxChars(exceedsLimit);
                            publish("attributeCharLimitExceeded", { attributeCode: keyAttribute.attributeCode, exceedsLimit });
                            if (chosenTrad === "") {
                                setChosenTrad(serviceTrad)
                            }
                            setServiceTrad(newTraduction);
                        }}
                        disabled={(serviceTrad === "x") || (!checked && chosenTrad === "")}

                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            maxHeight: '10%',
                            resize: 'none',
                            minHeight: '60px'
                        }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: "-20%",
                            right: "0",
                        }}
                    >
                        {serviceTrad.length}
                        {keyAttribute.maxCharactersAllowed !== 999999 && `/${keyAttribute.maxCharactersAllowed}`}
                    </div>
                    {serviceTrad.length >= keyAttribute.maxCharactersAllowed &&
                        <p style={{
                            color: "red",
                            position: "absolute",
                            right: "45%",
                            bottom: "90%",

                        }}>⚠️ Max : {keyAttribute.maxCharactersAllowed} caractères</p>}
                    {error && error.gravity === 'minor' && <div className="attributeErrorMessage" style={{
                        color: "orange",
                        position: "absolute",
                        right: "30%",
                        bottom: "105%",
                    }}>{error.message}</div>}
                    {error && error.gravity === 'major' && <div className="attributeErrorMessage" style={{
                        color: "red",
                        position: "absolute",
                        right: "30%",
                        bottom: "105%",
                    }}>{error.message}</div>}
                </div>
            </div>
            <div >
                <Tooltip text={`Click to show/hide the value of "${keyAttribute.attribute}" in Quable for the selected translation language.`}>
                    <img onClick={toggleTextArea} src={isTextAreaVisible ? upArrow : downArrow} alt="Toggle"
                         style={{width: "20px", height: "20px", paddingRight: "15px", cursor: 'pointer'  }}/>
                </Tooltip>
            </div>
            <div>
                {isTextAreaVisible && (
                    <textarea
                        type="text"
                        value={actualTranslation}
                        style={{
                            width: '50%',
                            minHeight: '80px',
                            marginTop: '10px',
                            marginLeft: '50%',
                        }}
                        disabled={true}
                    />
                )}
            </div>
        </div>
    </>);
}

export default Attribute;