import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import Axios from 'axios';
import '../style/Traductor.css'
import Attribute from '../components/attribute';
import { publish, subscribe, unsubscribe} from '../events'

const NewTraductorApp = () => {
    document.title="Traductor Qapp"
    const navigate = useNavigate();
    const search = useLocation().search;
    // URL PARAMS
    //const pimInstance = new URLSearchParams(search).get('quableInstance')
    let pimInstance = new URLSearchParams(search).get('quableInstance') // TODO - Cette ligne sert a fonctionner au niveau de l'instance clubMed il faudra la revoir
    pimInstance.replace(/_/g, '-');
    const quableDocID = new URLSearchParams(search).get('documentCode')
    const quableDocType = new URLSearchParams(search).get('documentType')
    const token = new URLSearchParams(search).get('token')
    const quableUserID = new URLSearchParams(search).get('quableUserID')
    const userLocale = new URLSearchParams(search).get('userLocale')
    const dataLocale = new URLSearchParams(search).get('dataLocale')
    const [savingAttributes, setSavingAttributes] = useState([]);
    let isSaving = savingAttributes.length > 0;
    const handleSaveStart = (attributeCode) => {
        setSavingAttributes((prev) => [...prev, attributeCode]);  // Add attribute to saving state
    };

    const handleSaveEnd = (attributeCode) => {
        setSavingAttributes((prev) => prev.filter((code) => code !== attributeCode));  // Remove attribute from saving state
        isSaving = savingAttributes.length > 0;
        fetchDocData();
    };


    //Creation de l'URL
    let backURL = "/api/translate"
    let errorURL = "/api/error"
    if (process.env.NODE_ENV === "development")
    {
        backURL="http://localhost:4000/api/translate"
        errorURL="http://localhost:4000/api/error"
    }
    //console.log("URL : ",backURL, " ",process.env.NODE_ENV)

    //Event Handling
    const sendAttrToPim = () => {
        setEnEnvoiPIM(true)
        publish('sendToPim');
    }

    //States
    //const [currentDoc,setCurrentDoc]=useState(quableDocID) //3517360023398_5001485_C04493
    const [docData,setDocData]=useState(null)
    const [originLocale,setOriginLocale]=useState("")
    const [targetLocale, setTargetLocale] = useState("")
    const [keyValue,setKeyValue]=useState([])
    const [expectedAttributes,setExpectedAttributes]=useState([])
    const [authorizedFields,setAuthorizedFields]=useState([])
    const [userLocales,setUserLocales]=useState([])
    const [preLoadTrad,setPreLoadTrad]=useState([])
    const [traductedData,setTraductedData]=useState(null)
    const [enTraduction,setEnTraduction]=useState(false)
    const [enEnvoiPIM,setEnEnvoiPIM]=useState(false)
    const [error,setError]=useState(false)
    const [checkAll,setCheckAll]=useState(false)
    const [isAdmin,setIsAdmin] = useState(false)
    const [userName,setUserName] = useState("")
    const [hasExceededMaxChars, setHasExceededMaxChars] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [canClose, setCanClose] = useState(false);

    //Async (quable) Functions
    async function fetchDocData()
    {
        try
        {
            //setErrorFlag(false)

            const response = await Axios({
                method: 'GET',
                url: pimInstance+"/documents/"+quableDocID,
                headers:{
                    accept: 'application/ld+json',
                    Authorization:"Bearer " + token
                }
            });
            setDocData(response.data.attributes)
        }
        catch
        {
            console.log("Error Document not found")
            setDocData(null)
            //setErrorFlag(true)
        }
    }

    async function queryAttributes()
    {
        try
        {
            let page=1;
            let attributes = [];
            let allAttributes = [];
            let hasAttributes = true;
            while (hasAttributes) {
                attributes = await Axios({
                        method: "GET",
                        url: pimInstance + "/attributes?page=" + page.toString() + "&type[0]=localized_text&type[1]=html_text&type[2]=multiline_text&documentTypeId=" + quableDocType,
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    }
                )
                if (attributes.data["hydra:member"].length == 0) {
                    hasAttributes = false;
                }
                else {
                    allAttributes = allAttributes.concat(attributes.data["hydra:member"]);
                    page++;
                }
            }

            setExpectedAttributes(allAttributes)
        }
        catch
        {
            console.log("Error in fetching attributes infos")

        }

    }

    async function fetchAuthorizedFields()
    {

        let userAuth=""
        try
        {

            const user = await Axios({
                    method: "GET",
                    url: pimInstance+"/users/"+quableUserID,
                    headers:{
                        Authorization:"Bearer " + token
                    }
                }
            )
            //fetch the user locales
            setUserLocales(user.data.localesAvailable)
            setUserName(user.data.username)

            if (Boolean(user.data.admin))
            {
                userAuth ="admin"
                setIsAdmin(true)
                //console.log("Is Admin")
            }
            else
            {

                if (user.data.permissionField==null)
                {
                    //console.log("Is NaN")
                    userAuth=null
                }
                else
                {
                    // console.log("Is not NaN")
                    userAuth =user.data.permissionField
                }
            }
        }
        catch
        {
            console.log("Error in fetching user")
        }
        //console.log("Recherche des champs autorisés",userAuth)

        if(userAuth!="admin" && userAuth!=null)
        {

            try
            {
                const authFields = await Axios({
                        method: "GET",
                        url: pimInstance+"/permissions/fields/"+userAuth,
                        headers:{
                            Authorization:"Bearer " + token
                        }
                    }
                )
                //console.log("data",authFields.data.fieldsVisible,authFields.data.fieldsEditable)
                setAuthorizedFields(authFields.data.fieldsVisible.filter(element => authFields.data.fieldsEditable.includes(element)))

            }
            catch
            {
                console.log("Error in fetching authorized fields")
            }
        }
        else
        {
            //Admin user or access to every field
            if (userAuth=="admin")
            {
                setAuthorizedFields(["administrateur"])
            }
            else
            {
                setAuthorizedFields(["all_fields"])
            }
        }
        //console.log(authorizedFields)
    }

    function displayAttributes(attrList, traductedData)
    {
        const attributesDisplayable = [];
        let compteAttributs = 0
        let maxCharactersAllowed
        for (const key in attrList)
        {
            let value;
            let localizedData = attrList[key];
            let attributeRealName = "";
            let translated=""
            let idTraduction=""
            let actualTranslation=""
            for (let i = 0; i < expectedAttributes.length; i++)
            {
                //console.log(key," ",expectedAttributes[i].id)
                //console.log(authorizedFields)
                if (key == expectedAttributes[i].id && (authorizedFields.includes(key)||authorizedFields.includes("all_fields")||authorizedFields.includes("administrateur")))
                {
                    compteAttributs++
                    //Prendre anglais par défault
                    attributeRealName=expectedAttributes[i].name[userLocale]?expectedAttributes[i].name[userLocale]:expectedAttributes[i].name["en-US"]
                    attributeRealName=attributeRealName?attributeRealName:expectedAttributes[i].name[originLocale.replace("_","-").replace("GB","US")]
                    //console.log("attributeRealName : ",attributeRealName)

                    localizedData=attrList[key]
                    //console.log("localized data : ",localizedData)
                    value = String(localizedData[originLocale]);
                    //console.log(value)
                    actualTranslation = String(localizedData[keyValue[targetLocale].PIM])

                    maxCharactersAllowed=expectedAttributes[i].options.maxCharactersAllowed?expectedAttributes[i].options.maxCharactersAllowed:999999

                    //Version API de Felipe
                    translated=traductedData?traductedData.translatedText[compteAttributs-1]:"x"
                    idTraduction=traductedData?traductedData.translationIds[compteAttributs-1]:"x"

                    //Version Mon API
                    //translated=traductedData?traductedData.translations[compteAttributs-1].text:"x"
                    if (!isNaN(localizedData[originLocale])) {
                        value=""
                        actualTranslation=""
                    }
                    attributesDisplayable.push(
                        <>
                            {<Attribute
                                key={compteAttributs}
                                attributeCode={expectedAttributes[i].id}
                                attribute={attributeRealName}
                                trad={String(translated)}
                                value={value}
                                locale={keyValue[targetLocale].PIM}
                                maxCharactersAllowed={maxCharactersAllowed}
                                translationId={String(idTraduction)}
                                userName={userName}
                                actualTranslation={actualTranslation}
                                onSaveStart={handleSaveStart}
                                onSaveEnd={handleSaveEnd}
                            />}
                        </>
                    )

                    break
                }

            }
        }
        return<>{attributesDisplayable}</>
    }

    async function handleTraduction(attrList)
    {
        setErrorMessage("")
        setEnTraduction(true)
        setError(false)
        let toTraduction=[]
        let traduce= false
        for (const key in attrList)
        {
            let value;
            let localizedData = attrList[key];
            let traduceAttrFlag=false;
            for (let i = 0; i < expectedAttributes.length; i++)
            {
                if (key == expectedAttributes[i].id && (authorizedFields.includes(key)||authorizedFields.includes("all_fields")||authorizedFields.includes("administrateur")))
                {
                    traduceAttrFlag=true
                    break
                }
            }
            if (traduceAttrFlag)
            {
                //Do i traduce ?
                for (const elem in preLoadTrad)
                {
                    //console.log("elem : ",preLoadTrad[elem],' ',key)
                    if (key == preLoadTrad[elem].attributeCode && preLoadTrad[elem].instruction)
                    {
                        //Yes ! find value
                        localizedData=attrList[key]
                        value = String(localizedData[originLocale])
                        //console.log("True found, added to trad")
                        toTraduction.push(value)
                    }
                    else
                    {
                        if(key == preLoadTrad[elem].attributeCode)
                        {
                            //No... Push empty string !!!
                            //console.log("not selected to be traduced : ")
                            value=""
                            toTraduction.push(value)
                        }
                    }

                }
            }
        }
        //console.log("va etre traduit : ",toTraduction)
        //Vérifier qu'il y a au moins un element en true
        for (let i = 0; i<toTraduction.length;i++)
        {
            if(Boolean(toTraduction[i]))
            {
                traduce=true
                break
            }
        }

        if (traduce)
        {
            try {
                const traduct = await Axios(
                    {
                        method:"POST",
                        url:backURL,

                        //Version de Felipe
                        data:{
                            "input": toTraduction,
                            "inputLanguage": findLocaleName(originLocale),
                            "outputLanguage":targetLocale,
                            "quableInstanceName": pimInstance,
                            "quableTokenApi": token
                        }

                    }
                )
                //console.log(traduct.data)
                setTraductedData(traduct.data)
                setEnTraduction(false)
            } catch (error) {
                if (error.response) {
                    setErrorMessage(error.response.data.error || "An error occurred during translation.");
                } else {
                    setErrorMessage("An unexpected error occurred.");
                    try {
                        const responseError = await Axios({
                            method: "POST",
                            url: errorURL,
                            data: {
                                message: "An unexpected error occurred during translation.",
                                gravity: "minor"
                            }
                        })
                    } catch (error) {
                    }

                }
                setEnTraduction(false);
            }
        }
        else
        {
            setEnTraduction(false)
            setError(true)
        }
    }

    async function getKeyValue()
    {
        let result
        try
        {

            const response = await Axios({
                method: 'GET',
                url: pimInstance+"/keyvalues/locals_traduction",
                headers:{
                    accept: 'application/ld+json',
                    Authorization:"Bearer " + token
                }
            });
            result=JSON.parse(response.data.value)
            setKeyValue(result)
            //console.log("key value ",Object.keys(result))
        }
        catch
        {
            console.log("Error Document not found")
            setDocData(null)
            //setErrorFlag(true)
        }
    }

    function findLocaleName(locale, withSplit = false)
    {
        let resultKey = null;
        for (const key in keyValue) {
            if (keyValue[key].PIM === locale) {
                resultKey = key;
                if (withSplit) {
                    return resultKey.split(" ")[0]
                }
                else {
                    return resultKey
                }
            }
        }
    }

//Effects
//Au chargement de la page
useEffect(() => {
  const handleCharLimitExceeded = (event) => {
    const { exceedsLimit } = event.detail;

    if (exceedsLimit) {
      setHasExceededMaxChars(true);
    } else {
      setHasExceededMaxChars(false);
    }
  };

  subscribe('attributeCharLimitExceeded', handleCharLimitExceeded);

  return () => {
    unsubscribe('attributeCharLimitExceeded', handleCharLimitExceeded);
  };
}, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getKeyValue();
                await fetchAuthorizedFields();
                await queryAttributes();
                await fetchDocData();

            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {

        setOriginLocale("EN")
        if (keyValue && userLocales.length > 0) {
            const validLanguage = Object.keys(keyValue).find(language =>
                userLocales.includes(keyValue[language].PIM)
            );

            if (validLanguage) {
                setTargetLocale(validLanguage);
            }
        }
    }, [keyValue, userLocales]);

    useEffect(()=>{
        //console.log("NewLocale :",originLocale)
        fetchDocData();
    },[originLocale])

    useEffect(()=>{
        displayAttributes(docData,traductedData)
    },[docData])

    useEffect(()=>{
        publish("resetAttributeStates")
        setTraductedData(null)
        preLoadTrad.map(item => { item.instruction = false; return item; });
        setPreLoadTrad(preLoadTrad)
    }, [targetLocale])

    useEffect(() => {
        let tradArray = preLoadTrad
        subscribe("chargeIntoTraductor", event => {
            //console.log("Caught event chargeIntoTraductor ")
            //console.log(event.detail)

            setError(false)
            tradArray = tradArray.filter(item => item.attributeCode !== event.detail.attributeCode);

            // Ajouter le nouveau JSON
            tradArray.push(event.detail);
            setPreLoadTrad(tradArray)
        });

        subscribe("endOfPimUpdate",() =>{
            setTimeout(() => {
                setEnEnvoiPIM(false)
            }, 1000);
            fetchDocData()
        })

        return () => {
            unsubscribe("chargeIntoTraductor", () => console.log("killed event chargeIntoTraductor"));
            unsubscribe("endOfPimUpdate", () => console.log("killed event endOfPimUpdate"));
        }
    },[]);

    //beforeUnload
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (!canClose) {
                const message = "You have unsaved changes. Are you sure you want to leave?";
                event.preventDefault();
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [canClose]);

    return(
        <>
            <div className='actionBorder'>
                <div className='actionGroup'>
                    <div className='selectorsDiv'>
                        <div></div>
                        <div>
                            <label style={{marginTop:"5px"}} htmlFor='originLocale'>Origin Language :&nbsp;</label>
                            <select
                                id='originLocale'
                                className='selectBox'
                                value={originLocale}
                                onChange={e => setOriginLocale(e.target.value)}
                                style={{marginRight:"20px"}}
                            >
                                {userLocales
                                    .filter(locale => ["EN", "FR", "es_ES", "en_SG"].includes(locale))
                                    .map((locale,index) =>(
                                    <option key={index} value={locale}>
                                        {findLocaleName(locale, true)}
                                    </option>)
                                )}
                            </select>
                            <label  style={{marginLeft:"20px", marginTop:"5px"}} htmlFor='targetLocale'>Translation Language :&nbsp;</label>
                            <select
                                id='targetLocale'
                                className='selectBox'
                                value={targetLocale}
                                onChange={e => {
                                    setTargetLocale(e.target.value)
                                }}
                            >
                                {Object.keys(keyValue).map((language,index) => {
                                        if (userLocales.includes(keyValue[language].PIM)/* && language!=findLocaleName(originLocale)*/)
                                        {
                                            return(
                                                <option key={index} value={language}>
                                                    {language}
                                                </option>
                                            )
                                        }
                                    }
                                )}
                            </select>
                        </div>
                        <div>
                            {isAdmin&&<button className='blueButton' onClick={()=>navigate("/dashboard", { state: {pimInstance} })}>Dashboard</button>}
                        </div>
                    </div>
                    <div className='actionsButtonDiv'>
                        {!(isSaving||enTraduction) && <button className='blueButton' onClick={() => handleTraduction(docData)}>Translate</button>}
                        {!(isSaving||enTraduction) && <button className={traductedData && !hasExceededMaxChars ?'orangeButton':'greyButton'} onClick={sendAttrToPim} disabled={traductedData && !hasExceededMaxChars ? false : true }>Save in Quable</button>}
                    </div>
                    {(enTraduction || isSaving) &&
                        <TailSpin
                            visible={true}
                            height="80"
                            width="1000"
                            color="#4d8acc"
                            radius="1"
                        />}
                    <p style={{color:"red"}}>{error&&"No field are selected"}</p>
                    <p>{enTraduction && "Currently translating "} {isSaving && " Saving in Quable"}</p>
                </div>
            </div>
            <div className='parentDiv'>
                <div className='utilsDiv'>
                    <div>
                        <input
                            id='checkall'
                            type='checkbox'
                            value={checkAll}
                            onChange={()=>{
                                publish("checkAllEvent",{instruction:!checkAll})
                                setCheckAll(!checkAll);
                            }}
                        />
                        <label htmlFor='checkall'>Select all</label>
                    </div>
                    {errorMessage && (
                    <div className="error-message" style={{ color: 'red', marginTop: '10px' }}>
                        {errorMessage}
                    </div>
                    )}
                    <button className='redButton' onClick={()=>window.location.reload()}>Reset</button>
                </div>
                { <div className='attributesContainer'>{displayAttributes(docData, traductedData)}</div>}
                <p>{traductedData ? "Traduit avec " + traductedData.service : ""}</p>
            </div>
        </>)
};

export default NewTraductorApp;