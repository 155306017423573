import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Axios from 'axios';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the Data Grid
import '../style/Dashboard.css'
import backArrow from "../props/backArrow.png"
import * as XLSX from 'xlsx';
import { useLocation } from "react-router-dom";

const Dashboard = () => {
    document.title="Dashboard Club Med"
    const location = useLocation();
    const pimInstance = location.state?.pimInstance;
    const navigate = useNavigate();
    let backURL = "/api/data/grouped";
    let postURL = "/api/data/range";
    if (process.env.NODE_ENV === "development")
    {
        backURL="http://localhost:4000/api/data/grouped"
        postURL="http://localhost:4000/api/data/range"
    }
    const today = new Date();
    const [rowDBData, setRowDBData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth(), 2).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 1).toISOString().split('T')[0]);
    const [colDefs, setColDefs] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    function mergeLanguages(array) {
        return array.map(row => {
            if (row.inputLanguage && row.outputLanguage) {
                row.inputLanguage += ' - ' + row.outputLanguage;
                delete row.outputLanguage
            }

            return row;
        });
    }

    const queryDBdata = async (url = backURL, params = {}) => {
        try {
            const databaseData = await Axios({
                method: "POST",
                url,
                data: params
            });
            if (databaseData.data.length === 0) {
                setErrorMessage('No data found for the selected dates.');
                setRowDBData([])
                return;
            }
            var sortedTranslations = databaseData.data.sort((a, b) => b.translations - a.translations);
            sortedTranslations = mergeLanguages(sortedTranslations);
            setRowDBData(sortedTranslations);
            let columns = Object.keys(sortedTranslations[0]).map((col, index) => ({
                field: col,
                pinned: index === 0 ? 'left' : null,
                width: index === 0 ? "275px" : null
            }));
            setColDefs(columns);
        } catch {
            console.log("Error in fetching database data");
            setErrorMessage('Error in fetching database data. Contact the administrator.');
        }
    };
    

    function formatDateWithoutYear(date) {
        date = new Date(date);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedMonth = month.toString().padStart(2, '0');
        const formattedDay = day.toString().padStart(2, '0');

        return `${formattedMonth}-${formattedDay}`;
    }
    
    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(rowDBData);
        const wb = XLSX.utils.book_new();
        const defaultWidth = 20;
        const columnWidths = rowDBData.length ? Object.keys(rowDBData[0]).map(() => ({ wch: defaultWidth })) : [];
        
        ws['!cols'] = columnWidths;
        XLSX.utils.book_append_sheet(wb, ws, "Translations");
        XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
        XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        XLSX.writeFile(wb, 'Translations_' + formatDateWithoutYear(startDate) + '_to_' + formatDateWithoutYear(endDate) + '.xlsx');
    };

    useEffect(()=>{
        queryDBdata(postURL, { startDate, endDate, pimInstance });
    },[])

    const handleFetchData = () => {
        if (startDate && endDate) {
            setErrorMessage('');
            queryDBdata(postURL, { startDate, endDate, pimInstance });
        }
    };

    return(
        <div>
            <div className='dashboardTitle'>
                <div className='inputDatesDiv'>
                    <div style={{'padding-left': '40px'}}>
                        <h4>Start Date: </h4>
                    </div>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                    />
                    <div>
                        <h4>End Date: </h4>
                    </div>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="End Date"
                    />
                    <button
                        className={new Date(startDate) > new Date(endDate) ? 'greyButton' : 'blueButton'}
                        onClick={handleFetchData}
                        style={{
                            margin: '20px',
                        }}
                        disabled={new Date(startDate) > new Date(endDate)}
                    >
                        Update
                    </button>
                    <button
                        className='blueButton'
                        onClick={() => downloadExcel(rowDBData)}
                        style={{
                            margin: '20px',
                            cursor: "pointer"
                        }}>
                        Download
                    </button>
                    {{ errorMessage } && <div style={
                        {
                            color: 'red',
                            margin: '30px'
                        }
                    }>{errorMessage}</div>}
                </div>
                <button
                    className='blueButton'
                    onClick={() => navigate(-1)}
                    style={{
                        margin: '20px',
                        cursor: "pointer"
                    }}
                >
                    Back to Translator
                </button>
            </div>
            <div
                className="ag-theme-material"
                style={{height: 500}}
            >
                <AgGridReact
                    style={{width: '80%', height: '100%'}}
                    rowData={rowDBData}
                    columnDefs={colDefs}
                    rowSelection='single'
                    columnSize="flex"
                    suppressDragLeaveHidesColumns='True'
                    domLayout='autoHeight'
                />
            </div>
        </div>
    )
}
export default Dashboard;