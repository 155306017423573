import React, { useState } from 'react';

const Tooltip = ({ text, children }) => {
    const [visible, setVisible] = useState(false);

    return (
        <div
            style={{ position: 'relative', display: 'inline-block' }}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {children}
            {visible && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '100%',
                        left: '50%',
                        fontFamily: 'Arial, sans-serif',
                        transform: 'translateX(-50%)',
                        padding: '5px',
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '4px',
                        whiteSpace: 'nowrap',
                        zIndex: 1000
                    }}
                >
                    {text}
                </div>
            )}
        </div>
    );
}

export default Tooltip;
