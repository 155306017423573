import Home from './pages/home';
import ErrorPage from './pages/error'
import NewTraductor from './pages/traductor';
import Dashboard from './pages/dashboard';
import './App.css';

import {
  BrowserRouter ,
  Routes,
  Route
} from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route index element={<Home />} />
        <Route path='*' element={<ErrorPage />} />
        <Route path='traductor' element={<NewTraductor />} />
        <Route path='dashboard' element={<Dashboard />} />
    </Routes>
  </BrowserRouter>
  )
}



export default App;
